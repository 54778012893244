import { PushNotifications } from "@capacitor/push-notifications";
import * as UserFunctions from "../firebase/UserFunctions";
import { isNativePlatform } from "../firebase/UserFunctions";
import { logError } from "./ErrorUtils";

export async function initPushNotifications(existingPushToken?: string) {
  if (!isNativePlatform()) {
    return;
  }

  PushNotifications.addListener("registration", (pushToken) => {
    if (existingPushToken !== pushToken.value) {
      console.log("UPT");
      UserFunctions.updatePushNotificationToken(pushToken.value);
    }
  });
  PushNotifications.addListener("registrationError", (e) =>
    logError(new Error(e.error), "Error registering push notification"),
  );
  PushNotifications.addListener("pushNotificationReceived", (e) =>
    console.log("Push received", e),
  );
  PushNotifications.register();
  // await askForNotificationPermissions();
}

export async function askForNotificationPermissions() {
  if (!(await shouldAskForNotificationPermissions())) {
    return;
  }

  return PushNotifications.requestPermissions();
}

export async function shouldAskForNotificationPermissions() {
  if (!isNativePlatform()) {
    return false;
  }
  const permissions = await PushNotifications.checkPermissions();
  switch (permissions.receive) {
    case "granted":
    case "denied":
      return false;
    case "prompt":
    case "prompt-with-rationale":
      return true;
  }
}
